.contact-wrapper {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  background-size: cover;
}

.contact-container {
  margin: 7rem 24rem;
  padding-top: 10px;
  background-color: rgba(255, 255, 255, 1);
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 36px;
  padding: 2rem 10rem;
}

.contact-header {
  font-size: 46px;
  font-family: "Comfortaa", cursive;
  color: rgb(58, 58, 58);
}

.contact-subheader {
  font-size: 26px;
  font-weight: 300;
}

.contact-input {
  border: none;
  border-bottom: 2px solid #adadad;
  margin-bottom: 36px;
  width: 100%;
}

.contact-input:focus,
.contact-textarea:focus {
  outline: none !important;
  border-bottom: 1px solid red;
  box-shadow: 4px 4px 4px #719ece;
}

.contact-label {
  color: #353535;
  align-self: flex-start;
}

.contact-submit {
  width: 10rem;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  padding: 5px 10px;
  border-radius: 4px;
  background-color: rgba(50, 17, 71, 1);
  color: #fff;
  transition: all 0.2s;
}

.contact-submit:hover {
  background-color: rgb(250, 216, 222);
  color: #fff;
  background-color: #eafdf8;
  color: rgb(104, 104, 104);
  border: 1px solid rgb(104, 104, 104);
  box-shadow: 4px 4px 4px #719ece;
}

.contact-textarea {
  border: none;
  border-bottom: 2px solid #adadad;
  margin-bottom: 36px;
  min-height: 115px;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .contact-container {
    margin: 105px auto;
    width: 65%;
  }

  .contact-textarea {
    margin-bottom: 26px;
    min-height: 65px;
  }

  .contact-input {
    margin-bottom: 26px;
  }

  .contact-form {
    margin-top: 1rem;
    padding: 1rem 2.5rem;
  }
}

@media only screen and (max-width: 426px) {
  .contact-container {
    width: 85%;
  }

  .contact-form {
    padding: 0.5rem;
  }

  .contact-header {
    font-size: 36px;
  }

  .contact-subheader {
    font-size: 20px;
  }

  .contact-textarea {
    margin-bottom: 16px;
    min-height: 62px;
  }

  .contact-input {
    margin-bottom: 16px;
  }
}
