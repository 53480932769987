.hero-background {
  height: 100vh;
  background-image: url("./Images/pexels-ali-pazani.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.hero-content {
  color: #fff;
  padding-top: 18rem;
  text-align: left;
  padding-left: 14rem;
}

.hero-title {
  font-size: 84px;
  font-family: "Comfortaa", cursive;
  letter-spacing: 3px;
  margin-bottom: 1.5rem;
}

.hero-mobile-title {
  display: none;
}

.hero-subtext {
  font-size: 44px;
  font-weight: 300;
  color: #ffffff;
  font-family: "Comfortaa", cursive;
}

.hero-subtext-container {
  margin-top: 3rem;
  margin-left: 3rem;
}

.hero-link {
  cursor: pointer;
  border: none;
  outline: none;
  font-weight: 700;
  font-size: 16px;
  border-radius: 2px;
  padding: 0.5rem 2rem;
  margin-left: 4rem;
  background-color: #e5e9ec;
  transition: all 0.3s;
  text-decoration: none;
  color: rgba(71, 71, 71, 1);
}

.hero-link:hover {
  background-color: rgba(218, 218, 218, 0.5);
  color: #e5e9ec;
  text-decoration: none;
}

@media only screen and (max-width: 768px) {
  .hero-content {
    padding-top: 18rem;
    padding-left: 5rem;
  }

  .hero-title {
    font-size: 68px;
  }

  .hero-subtext {
    font-size: 34px;
  }

  .hero-subtext-container {
    margin-top: 2rem;
  }
}

@media only screen and (max-width: 460px) {
  .hero-content {
    padding-top: 1rem;
    padding-left: 1rem;
  }

  .hero-title {
    display: none;
  }

  .hero-mobile-title {
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
    margin-bottom: 0.5rem;
  }

  .hero-mobile-text {
    font-size: 54px;
    font-family: "Comfortaa", cursive;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
  }

  .hero-background {
    background-attachment: scroll;
    height: 60vh;
  }

  .hero-subtext {
    font-size: 20px;
  }

  .hero-subtext-container {
    margin-left: 1rem;
    margin-top: 1.5rem;
  }
}
