.about-container {
  padding: 0 0 3rem 0;
  background-color: #fff;
  margin: 0;
}

.about-content {
  width: 100%;
  z-index: 2;
  padding-top: 3rem;
}

.about-title {
  margin: 4rem 0 3rem 5rem;
  text-align: left;
  font-weight: 300;
  font-size: 46px;
  font-family: "Comfortaa", cursive;
  color: rgb(58, 58, 58);
  letter-spacing: 2px;
}

.about-profile-picture {
  width: 200px;
  height: 180px;
  border: 1px solid rgba(50, 17, 71, 1);
  padding: 4px;
  margin: 0 20px;
}

.about-inner-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 3rem;
  margin-right: 16rem;
  margin-left: 16rem;
}

.about-text {
  text-align: left;
  margin-top: 1rem;
  color: rgb(46, 46, 46);
  line-height: 2.1;
}

.about-text-container {
  margin-left: 2rem;
}

@media only screen and (max-width: 1024px) {
  .about-container {
    margin: -60px 0;
  }

  .about-inner-content {
    margin-left: 6rem;
    margin-right: 6rem;
  }
}

@media only screen and (max-width: 768px) {
  .about-inner-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .about-title {
    margin: 4rem 0 2rem 3rem;
    font-size: 40px;
  }
}

@media only screen and (max-width: 460px) {
  .about-container {
    padding-bottom: 0;
  }

  .about-inner-content {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .about-profile-picture {
    height: 160px;
    width: 160px;
    margin-bottom: 2rem;
  }

  .about-title {
    font-size: 28px;
    margin: 6rem 0 2rem 1rem;
  }
}
