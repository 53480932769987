.gallery-container {
  padding: 10rem 5rem;
}

.gallery-page {
  margin: 0;
  padding: 0;
  background-color: #fff;
}

.gallery-title {
  margin: 0 0 3rem 5rem;
  text-align: left;
  font-weight: 300;
  font-size: 46px;
  font-family: "Comfortaa", cursive;
  color: rgb(58, 58, 58);
}

.gallery-card {
  margin: 24px;
}

@media only screen and (max-width: 768px) {
  .gallery-container {
    padding: 6rem 4rem 2rem;
  }

  .gallery-card {
    margin: 10px;
  }

  .gallery-title {
    margin: 0 0 3rem 2rem;
  }
}

@media only screen and (max-width: 426px) {
  .gallery-container {
    padding: 6rem 1.5rem 2rem;
  }

  .gallery-card {
    margin: 14px;
  }

  .gallery-title {
    margin-left: 0;
  }
}
