.navbar {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: fixed;
  color: #fff;
  width: 100%;
  transition-timing-function: ease-in;
  transition: all 0.3s;
  z-index: 4;
  padding: 15px 90px 0;
  background: transparent;
  height: 80px;
}

.logo {
  width: 70px;
}

.menu-icon {
  display: none;
}

.nav-color {
  background-color: rgba(50, 17, 71, 1);
}

.container-fluid {
  width: 50%;
  padding: 0;
  margin: 0;
  height: 60px;
}

.nav-link {
  color: #fff;
}

.nav-link:hover {
  color: #e5e9ec;
}

.mobile-menu {
  position: fixed;
  right: 0;
  padding-top: 60px;
  padding-right: 15px;
  background-color: rgba(50, 17, 71, 1);
  height: 100vh;
  width: 200px;
}

.mobile-menu-links {
  list-style-type: none;
  display: flex;
  flex-direction: column;
}

.mobile-link {
  margin: 14px 0;
  text-decoration: none;
  color: #fff;
  font-size: 20px;
}

.mobile-close-icon {
  position: fixed;
  right: 22px;
  top: 22px;
  width: 22px;
}

@media only screen and (max-width: 1024px) {
  .navbar {
    padding: 15px 70px 0;
  }
}

@media only screen and (max-width: 768px) {
  .navbar {
    padding: 1px 10px 0;
  }
}

@media only screen and (max-width: 426px) {
  .navbar {
    padding: 1px 10px 0;
  }

  .nav-link-box {
    display: none;
  }

  .menu-icon {
    display: block;
    width: 30px;
    margin: 16px 6px 0 0;
  }
}
