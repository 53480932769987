.info-wrapper {
  margin-top: 8rem;
  margin-bottom: 4rem;
}

.location-image {
  background-image: url("./Images/screenshot.png");
  width: 80vw;
  height: 25vh;
  background-position: center;
  background-size: cover;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
}

.location-address {
  padding: 0;
  margin: 0.5rem 0;
}

.hours-list {
  list-style-type: none;
  text-align: center;
}

.location-title,
.hours-title {
  font-size: 28px;
  text-align: left;
  margin: 6rem 0 1em 1rem;
  font-weight: 300;
  font-family: "Comfortaa", cursive;
  color: rgb(58, 58, 58);
  letter-spacing: 2px;
}

@media only screen and (min-width: 460px) {
  .info-wrapper {
    margin-top: 2rem;
    margin-bottom: 4rem;
    display: flex;
    flex-direction: row;
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .location-image {
    background-image: url("./Images/screenshot.png");
    width: 100%;
    height: 40vh;
    background-position: center;
    background-size: cover;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
  }

  .hours-wrapper,
  .location-wrapper {
    width: 80%;
  }

  .location-address {
    padding: 0;
    margin: 0.5rem 0;
  }

  .hours-list {
    list-style-type: none;
    text-align: left;
    margin-left: 2rem;
  }

  .location-title,
  .hours-title {
    margin: 4rem 0 3rem 2rem;
    text-align: left;
    font-weight: 300;
    font-size: 46px;
    font-family: "Comfortaa", cursive;
    color: rgb(58, 58, 58);
    letter-spacing: 2px;
  }

  .address-container {
    display: flex;
    flex-direction: row;
  }

  .location-address {
    margin: 5px;
  }
}
