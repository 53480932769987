#social-wrapper {
  margin: 0 15rem;
}

.footer-link-wrapper {
  margin: 0 12rem;
}

.footer-columns {
  justify-content: space-between;
}

#instagram,
#facebook,
#twitter {
  width: 22px;
}

.column1,
.column2 {
  width: 50%;
}

.footer-link {
  color: inherit;
}

.social-icons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.social-icon {
  margin: 8px;
}

.email-to {
  text-decoration: underline;
  color: rgb(109, 109, 109);
}

@media only screen and (max-width: 1024px) {
  .footer-link-wrapper {
    margin: 0 3rem;
  }

  #social-wrapper {
    margin: 0 7rem;
  }
}

@media only screen and (max-width: 768px) {
  .footer-link-wrapper {
    margin: 0 5rem;
  }

  #social-wrapper {
    margin: 0 9rem;
  }
}

@media only screen and (max-width: 426px) {
  .footer-link-wrapper {
    margin: 0 2rem;
  }

  #social-wrapper {
    margin: 0 3rem;
  }

  p {
    word-wrap: break-word;
    font-size: 14px;
  }
}
