.router-navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  position: fixed;
  color: #fff;
  width: 100%;
  transition-timing-function: ease-in;
  transition: all 0.3s;
  z-index: 4;
  padding: 15px 90px 0;
  height: 80px;
}

.router-navbar {
  background-color: rgba(50, 17, 71, 1);
}

.logo {
  width: 70px;
}

.router-container-fluid {
  padding: 0;
  margin: 0;
}

nav {
  padding: 15px 90px 0;
  background: transparent;
}

.nav-link {
  color: #fff;
}

.nav-link:hover {
  color: #e5e9ec;
}

@media only screen and (max-width: 768px) {
  .router-navbar {
    padding: 1px 10px 0;
  }
}

@media only screen and (max-width: 426px) {
  .router-navbar {
    padding: 1px 10px 0;
  }

  .nav-link-box {
    display: none;
  }
}
