.info-container {
  background-color: #fff;
  padding-top: 1rem;
  position: relative;
  z-index: 2;
}

.intro-text {
  font-size: 32px;
  font-weight: 300;
  border-bottom: 1px solid rgba(50, 17, 71, 1);
  border-top: 1px solid rgba(50, 17, 71, 1);
  margin: 8rem 8rem 10rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  letter-spacing: 3px;
}

em {
  font-weight: 400;
  letter-spacing: 3px;
}

.card-image {
  height: 300px;
  border-radius: 3rem 3rem 0 0;
  padding: 30px;
}

.services-grid {
  width: 70%;
}

.service-card {
  border-radius: 0;
  min-height: 350px;
  margin: 0;
  background-color: #fff;
}

#card-1 {
  border-right: 1px solid #c0c0c0;
  border-bottom: 1px solid #c0c0c0;
  border-top: none;
  border-left: none;
}

#card-2 {
  border-top: none;
  border-left: 1px solid #c0c0c0;
  border-right: none;
  border-bottom: 1px solid #c0c0c0;
}

#card-3 {
  border: 1px solid #c0c0c0;
  border-left: none;
}

#card-4 {
  border: 1px solid #c0c0c0;
  border-right: none;
}

#card-5 {
  border: 1px solid #c0c0c0;
  border-left: none;
}

#card-6 {
  border: 1px solid #c0c0c0;
  border-right: none;
}

#card-7 {
  border: 1px solid #c0c0c0;
  border-left: none;
  border-bottom: none;
}

.card-time {
  font-weight: 400;
  font-size: 18px;
  margin: 5px;
}

.book-button {
  cursor: pointer;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 16px;
  border-radius: 2px;
  padding: 0.5rem 2rem;
  background-color: #e5e9ec;
  transition: all 0.3s;
  text-decoration: none;
  color: rgba(50, 17, 71, 1);
}

.book-button:hover {
  background-color: aliceblue;
  color: rgb(104, 104, 104);
  border: 1px solid rgb(104, 104, 104);
}

.card-text {
  margin: 1.5rem;
  padding: 1rem;
  border-top: 1px solid #c0c0c0;
  border-bottom: 1px solid #c0c0c0;
  line-height: 1.7;
}

.service-gallery-text {
  margin-top: 6rem;
  font-size: 34px;
  font-weight: 300;
  color: rgb(46, 46, 46);
}

.service-arrow-button {
  width: 5rem;
  align-self: center;
  margin-top: 2rem;
  background-color: #fff;
  border: none;
  transition: all 0.2s;
}

.service-arrow-button:hover {
  background-color: #fff;
  transform: translateX(10px) scaleX(1.2);
}

#gallery-card {
  background-color: #fff;
  border: 1px solid #c0c0c0;
  border-right: none;
  border-bottom: none;
}

.row {
  margin: 0;
}

.card-body {
  margin-top: 20px;
}

.col-md {
  margin: 0;
  padding: 0;
}

.services-lash-image {
  margin: 5px;
  width: 100%;
}

.service-type-filter {
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  transition: all 0.2s;
}

.service-type-filter:hover {
  background-color: rgba(0, 0, 0, 0);
}

.service-lash-image-1 {
  width: 100%;
  height: 100%;
  background-image: url("./Images/IMG952144.jpg");
  background-position: center;
  background-size: cover;
}

.service-lash-image-2 {
  width: 100%;
  height: 100%;
  background-image: url("./Images/IMG952143.jpg");
  background-position: center;
  background-size: cover;
}

.service-lash-image-3 {
  width: 100%;
  height: 100%;
  background-image: url("./Images/IMG952142.jpg");
  background-position: center;
  background-size: cover;
}

.services-type {
  width: 100%;
  text-decoration: none;
  cursor: pointer;
}

.service-type-label {
  color: #fff;
  font-size: 34px;
  font-weight: 300;
  text-align: left;
  margin-left: 1.5rem;
  padding-top: 1rem;
}

.service-images-wrapper {
  margin: 2rem 0 0;
  width: 100%;
  height: 60vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.info-pricing,
.services-title {
  margin: 4rem 0 3rem 5rem;
  text-align: left;
  font-weight: 300;
  font-size: 46px;
  font-family: "Comfortaa", cursive;
  color: rgb(58, 58, 58);
  letter-spacing: 2px;
}

.info-pricing {
  margin-top: 8rem;
}

h3 {
  font-weight: 300;
  color: rgb(46, 46, 46);
}

#card-1-text {
  padding-bottom: 43px;
}

.custom-shape-divider-top-1646243444 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 120px;
}

.custom-shape-divider-top-1646243444 .shape-fill {
  fill: rgba(50, 17, 71, 1);
}

@media only screen and (max-width: 1024px) {
  .services-lash-image {
    width: 170px;
  }

  .info-pricing {
    margin-top: 6rem;
  }

  .card-text {
    line-height: 1.5;
  }

  #card-1-text {
    padding-bottom: 71px;
  }
}

@media only screen and (max-width: 768px) {
  .info-pricing,
  .services-title {
    margin: 4rem 0 2rem 3rem;
    font-size: 40px;
  }

  .intro-text {
    margin: 8rem 4rem 10rem;
  }

  .services-lash-image {
    width: 140px;
  }

  .service-images-wrapper {
    margin: 30px 0;
    height: 50vh;
  }

  .service-card {
    width: 100%;
    margin: 1rem 0;
  }

  #card-1-text {
    padding-bottom: 58px;
  }

  .card-text {
    font-size: 16px;
    line-height: 1.3;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .service-card {
    min-height: 326px;
    margin: 0;
  }

  .services-grid {
    width: 85%;
  }
}

@media only screen and (max-width: 460px) {
  .services-container {
    margin-top: 4rem;
  }

  .intro-text {
    font-size: 18px;
    font-weight: 300;
    border-bottom: 1px solid black;
    margin: 3.5rem 1rem 7rem;
    padding-bottom: 0.5rem;
  }

  .row {
    margin: 0;
  }

  .services-lash-image {
    width: 146px;
  }

  .service-images-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 80vh;
  }

  .service-lash-image-1 {
    background-position-y: -170px;
    background-size: cover;
  }

  .service-lash-image-2 {
    background-position-y: -90px;
    background-size: cover;
  }

  .service-lash-image-3 {
    background-position-y: -60px;
    background-size: cover;
  }

  #card-1 {
    padding-bottom: 1rem;
  }

  #card-1,
  #card-2,
  #card-3,
  #card-4,
  #card-5,
  #card-6,
  #card-7,
  #gallery-card {
    border: 1px solid #c0c0c0;
    margin: 5px 0;
  }

  .custom-shape-divider-top-1646243444 svg {
    height: 0;
  }

  .info-pricing,
  .services-title {
    font-size: 28px;
    margin: 6rem 0 3rem 1rem;
  }
}
